<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <!--begin::Form sendsms Wizard-->
      <form class="form" @submit.prevent="sendSmsPost" v-if="formType === 'sendSmsForm'">
        <!--begin::Step 1-->
        <div data-wizard-type="step-content" data-wizard-state="current">
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('password_change.old_password') }}</label>
                <div class="col-lg-9 col-xl-9">
                  <input
                    class="form-control"
                    name="projectname"
                    :type="passwordTypes.oldPasswordType"
                    v-model="sendSms.old_password"
                  />
                  <span class="float-right hover-opacity-50 cursor-pointer" @click="togglePasswordType('oldPasswordType')">{{ $t('password_change.show_hide_password') }}</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('password_change.new_password') }}</label>
                <div class="col-lg-9 col-xl-9">
                  <input
                    class="form-control"
                    name="projectname"
                    :type="passwordTypes.newPasswordType"
                    v-model="sendSms.new_password"
                  />
                  <span class="float-right hover-opacity-50 cursor-pointer" @click="togglePasswordType('newPasswordType')">{{ $t('password_change.show_hide_password') }}</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('password_change.new_password_again') }}</label>
                <div class="col-lg-9 col-xl-9">
                  <input
                    class="form-control"
                    name="projectname"
                    :type="passwordTypes.newPasswordAgainType"
                    v-model="sendSms.new_password_again"
                  />
                  <span class="float-right hover-opacity-50 cursor-pointer" @click="togglePasswordType('newPasswordAgainType')">{{ $t('password_change.show_hide_password') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="d-flex justify-content-end border-top mt-5 pt-10">
          <button
            type="submit"
            class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
          >
            {{ $t('password_change.send_sms') }}
          </button>
        </div>
        <!--end::Actions-->
      </form>
      <!--end::Form sendsms Wizard-->

      <!--begin::Form Wizard-->
      <form class="form" @submit.prevent="sendConfirmationPost" v-if="formType === 'sendConfirmationForm'">
        <!--begin::Step 1-->
        <div data-wizard-type="step-content" data-wizard-state="current">
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('password_change.send_code') }}</label>
                <div class="col-lg-9 col-xl-9">
                  <input
                    class="form-control"
                    name="projectname"
                    :type="passwordTypes.smsCodeType"
                    v-model="sendConfirmation.sms_code"
                  />
                  <span class="float-right hover-opacity-50 cursor-pointer" @click="togglePasswordType('smsCodeType')">{{ $t('password_change.show_hide_password') }}</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('password_change.new_password') }}</label>
                <div class="col-lg-9 col-xl-9">
                  <input
                    class="form-control"
                    name="projectname"
                    :type="passwordTypes.newPasswordType"
                    v-model="sendConfirmation.new_password"
                  />
                  <span class="float-right hover-opacity-50 cursor-pointer" @click="togglePasswordType('newPasswordType')">{{ $t('password_change.show_hide_password') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="d-flex justify-content-end border-top mt-5 pt-10">
          <button
            type="submit"
            class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
          >
            {{ $t('password_change.confirm') }}
          </button>
        </div>
        <!--end::Actions-->
      </form>
      <!--end::Form Wizard-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "PasswordChangeForm",
  data() {
    return {
      formType: "sendSmsForm",
      passwordTypes: {
        oldPasswordType: "password",
        newPasswordType: "password",
        newPasswordAgainType: "password",
        smsCodeType: "password"
      },
      sendSms: {
        old_password: "",
        new_password: "",
        new_password_again: "",
      },
      sendConfirmation: {
          sms_code: "",
          new_password: ""
      }
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.profile.isLoading,
    }),
  },
  methods: {
    ...mapActions("profile", [
      "CHANGE_PASSWD_SEND_SMS",
      "CHANGE_PASSWD_SEND_CONFIRM",
    ]),
    sendSmsPost() {
        if(!this.sendSms.new_password || !this.sendSms.new_password_again || !this.sendSms.old_password) {
          this.$toast.error("All fields are required!");
            return;
        }
        if(this.sendSms.new_password !== this.sendSms.new_password_again) {
          this.$toast.error("Password mismatch!");
          return;
        }
        this.CHANGE_PASSWD_SEND_SMS({form: this.sendSms}).then(() => {
          this.formType = "sendConfirmationForm"
        })
    },
    sendConfirmationPost() {
        if(!this.sendConfirmation.sms_code || !this.sendConfirmation.new_password) {
          this.$toast.error("All fields are required!");
            return;
        }
        this.CHANGE_PASSWD_SEND_CONFIRM({form: this.sendConfirmation}).then(() => {
          this.formType = "sendSmsForm"
          this.sendSms = {
              old_password: "",
              new_password: "",
              new_password_again: "",
          };
          this.sendConfirmation = {
              sms_code: "",
              new_password: ""
          };
        })
    },
    togglePasswordType(type) {
      this.passwordTypes[type] = this.passwordTypes[type] === 'password' ? 'text' : 'password';
    }
  },
  created() {
    
  },
};
</script>